import {
  STT_CREATED,
  STT_DUPLICATED,
  STT_EMPTY,
  STT_FORBIDDEN,
  STT_NOT_FOUND,
  STT_OK,
  STT_UNAUTHORIZED,
  STT_WRONG_PARAMS,
} from "src/const/app.const";
import { createApi } from ".";
import toast from 'react-hot-toast';
import { BusinessRequest } from "src/types/models";

export interface IJackpotEntry {
  businessName: string;
  jackpot: number;
  rewardName: string;
  userId: number;
  userName: string;
  createdAt: string;
  status: string;
  website: string;
  id: string;
}

export const getJackpots = async (data: {
  offset?: number;
  limit?: number;
}) => {
  try {
    if (!data?.offset || data?.offset < 0) {
      data.offset = 0;
    }

    const res = await createApi().get<{
      jackpot: any;
      total: number;
    }>(`/business-request`, data);

    if (res.status === STT_UNAUTHORIZED) {
      toast.error("Unauthorized");
      return { jackpot: [], total: 0 };
    }

    if (!res.data) {
      toast.error(res.status);
      return { jackpot: [], total: 0 };
    }

    if (!res.ok) {
      toast.error(res?.problem);
      return { jackpot: [], total: 0 };
    }

    return {
      totalJackpots: res.data.total,
      jackpotList: res.data.jackpot,
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getUserJackpots = async (offset?: number, limit?: number) => {
  try {
    const res = await createApi().get<{
      jackpot: IJackpotEntry[];
      total: number;
    }>(`/jackpot?offset=${offset}&limit=${limit}`);

    if (res.status === STT_EMPTY) {
      return { jackpot: [], total: 0 };
    }

    if (res.status === STT_UNAUTHORIZED) {
      toast.error(res.status);
      return { jackpot: [], total: 0 };
    }

    if (!res.data) {
      toast.error(res.status);
      return { jackpot: [], total: 0 };
    }
    return {
      total: res.data.total,
      jackpotList: res.data.jackpot,
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const enterJackpot = async (rewardId: string) => {
  const data = {
    rewardId: rewardId,
  };

  try {
    const res = await createApi().post<{
      message: string;
      success: boolean;
    }>("/jackpot", data);

    if (res.status === STT_UNAUTHORIZED) {
      toast.error("Sorry, you are not allowed to access this jackpot.");
    }
    if (res.status === STT_DUPLICATED) {
      toast.error("You have already entered this jackpot.");
    }
    if (res.status === STT_WRONG_PARAMS) {
      toast.error("Sorry, something went wrong.");
    }
    if (res.status === STT_NOT_FOUND) {
      toast.error("Sorry, this jackpot doesn't exist anymore.");
    }
    if (res.status === STT_FORBIDDEN) {
      toast.error("Sorry, you are not allowed to access this jackpot.");
    }
    if (res.status === STT_OK || res.status === STT_CREATED) {
      toast.success("Jackpot entered! Good luck.");
    }

    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getJackpotbyId = async (id: string) => {
  try {
    const res = await createApi().get<{
      ok: boolean;
      data: BusinessRequest;
    }>(`/business-request/${id}`);
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getUserEntries = async () => {
  try {
    const res = await createApi().get<{
      ok: true;
      data: number;
    }>(`/jackpot/remaining`);

    if (res.status === STT_NOT_FOUND) {
      toast.error("Sorry, this jackpot is not live anymore.");
      return 0;
    }
    if (res.status === STT_FORBIDDEN) {
      toast.error("Sorry, you cannot enter a jackpot more than one time.");
      return 0;
    }
    return res.data?.data || 0;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
