import moment from "moment";

// HTTP Status
export const STT_OK = 200;
export const STT_CREATED = 201;
export const STT_BAD_REQUEST = 400;
export const STT_UNAUTHORIZED = 401;
export const STT_FORBIDDEN = 403;
export const STT_NOT_FOUND = 404;
export const STT_DUPLICATED = 409;
export const STT_INTERNAL_SERVER = 500;
export const STT_NOT_MODIFIED = 304;
export const STT_EMPTY = 202;
export const STT_TO_MANY_REQUESTS = 429;
export const STT_WRONG_PARAMS = 422;

export const EMAIL_REGEX = /^\w+([\.-]?\w+)*@(?!hotmail\.|outlook\.)(\w+([\.-]?\w+)*\.\w{2,100})+$/;
export const PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%^&*?#_\-.])[A-Za-z\d@$!%^&*?#_\-.]{8,}$/;

export const COMMON_X_MARGIN =
  "mx-auto md:px-8  max-w-7xl  lg:max-w-[76.5rem]"; // The padding avoids the margin to disappear if the space is too little

export const DealSteps = [
  {
    step: 0,
    value: "edit",
    label: "Create",
  },
  {
    step: 1,
    value: "location",
    label: "Location",
  },
  {
    step: 2,
    value: "type",
    label: "Type",
  },
  {
    step: 3,
    value: "list",
    label: "List",
  },
  {
    step: 4,
    value: "description",
    label: "Description",
  },
  {
    step: 5,
    value: "price",
    label: "Price",
  },
  {
    step: 6,
    value: "quantity",
    label: "Quantity",
  },
  {
    step: 7,
    value: "validity",
    label: "Validity",
  },
  {
    step: 8,
    value: "photo",
    label: "Photo",
  },
  {
    step: 9,
    value: "expiration",
    label: "Expiration",
  },
];

export const GiftCardStepsOrder = [0, 1, 2, 5, 6, 9];
export const EventSteps = [
  { 
    step: 0,
    value: "edit",
    label: "Create",
  },
  {
    step: 1,
    value: "location",
    label: "Location",
  },
  {
    step: 2,
    value: "description",
    label: "Description",
  },
  {
    step: 3,
    value: "price",
    label: "Price",
  },
  {
    step: 4,
    value: "validity",
    label: "Date",
  },
  {
    step: 5,
    value: "photo",
    label: "Photo",
  },
  {
    step: 6,
    value: "expiration",
    label: "Expiration",
  },
];

export const categories = [
  {
    id: 14,
    name: "Art Studio",
    avatar: "/categories/art.svg",
    color: "bg-[#d3de6e]",
  },
  {
    id: 20,
    name: "Auto Shop",
    avatar: "/categories/auto.svg",
    color: "bg-[#34de6e]",
  },
  {
    id: 5,
    name: "Bakery",
    avatar: "/categories/bakery.svg",
    color: "bg-[#6edecb]",
  },
  {
    id: 1,
    name: "Bar",
    avatar: "/categories/bar.svg",
    color: "bg-[#6ecdde]",
  },
  {
    id: 7,
    name: "Beauty Shop",
    avatar: "/categories/beauty.svg",
    color: "bg-[#de6e6e]",
  },
  {
    id: 23,
    name: "Boat Tour Agency",
    avatar: "/categories/boat.svg",
    color: "bg-[#303e6e]",
  },
  {
    id: 22,
    name: "Book Store",
    avatar: "/categories/book.svg",
    color: "bg-[#5e4c6e]",
  },
  {
    id: 2,
    name: "Brewery",
    avatar: "/categories/brewery.svg",
    color: "#6ea8de",
  },
  {
    id: 25,
    name: "Chiropractor",
    avatar: "/categories/chiropractor.svg",
    color: "bg-[#hfh5d2]",
  },
  {
    id: 15,
    name: "Clothing Store",
    avatar: "/categories/clothing.svg",
    color: "#6e7fde",
  },
  {
    id: 4,
    name: "Coffee Shop",
    avatar: "/categories/coffee.svg",
    color: "#de8e6e",
  },
  {
    id: 27,
    name: "Coworking Space",
    avatar: "/categories/coworking.svg",
    color: "#hdef6e",
  },
  {
    id: 28,
    name: "Dance Studio",
    avatar: "/categories/dance.svg",
    color: "#jksf6e",
  },
  {
    id: 24,
    name: "Dentist",
    avatar: "/categories/dentist.svg",
    color: "bg-[#83hd32]",
  },
  {
    id: 17,
    name: "Distillery",
    avatar: "/categories/distillery.svg",
    color: "#da6ede",
  },
  {
    id: 11,
    name: "Entertainment",
    avatar: "/categories/entertainment.svg",
    color: "#8c6ede",
  },
  {
    id: 16,
    name: "Farm",
    avatar: "/categories/farm.svg",
    color: "#de6e6e",
  },
  {
    id: 19,
    name: "Farmers Market",
    avatar: "/categories/farmersMarket.svg",
    color: "#2489db",
  },
  {
    id: 12,
    name: "Florist",
    avatar: "/categories/florist.svg",
    color: "#fd6868",
  },
  {
    id: 6,
    name: "Food & Drink Shop",
    avatar: "/categories/food.svg",
    color: "#ded76e",
  },
  {
    id: 21,
    name: "General Store",
    avatar: "/categories/food.svg",
    color: "#ded76e",
  },
  {
    id: 9,
    name: "Gym",
    avatar: "/categories/gym.svg",
    color: "#526a97",
  },
  {
    id: 13,
    name: "Hair Salon",
    avatar: "/categories/hair.svg",
    color: "#97528b",
  },
  {
    id: 10,
    name: "Ice Cream Shop",
    avatar: "/categories/ice.svg",
    color: "#dbb124",
  },
  {
    id: 26,
    name: "Personal Trainer",
    avatar: "/categories/personaltrainer.svg",
    color: "bg-[#3kfg22]",
  },
  {
    id: 18,
    name: "Pet Shop",
    avatar: "/categories/petshop.svg",
    color: "#2489db",
  },
  {
    id: 0,
    name: "Restaurant",
    avatar: "/categories/resturant.svg",
    color: "#DEC06E",
  },
  {
    id: 8,
    name: "Wellness Center",
    avatar: "/categories/spa.svg",
    color: "#84de6e",
  },
  {
    id: 3,
    name: "Winery",
    avatar: "/categories/winery.svg",
    color: "#d35157",
  },
];

export const DATA_FORMAT = {
  DB: "YYYY-MM-DD HH:mm:ss",
};

export function formatDate(date: Date, format?: string) {
  if (!date) {
    return;
  }
  return moment(date).format(format || DATA_FORMAT.DB);
}

export const BUSINESS_ROLES = {
  PARTNER: "partner",
  LOCATION_MANAGER: "manager",
  ASSOCIATE: "associate",
};

export const ROLES_LABEL = {
  [BUSINESS_ROLES.PARTNER]: "Partner",
  [BUSINESS_ROLES.LOCATION_MANAGER]: "Manager",
  [BUSINESS_ROLES.ASSOCIATE]: "Associate",
};

export const STORAGE_REFERRAL = "referral";

export const RATES = {
  MIKI_RATE: 100, // 1$ => 100 MIKI POINTS,
  CENTS: 100,
  TRADE_IN_RATE: 0.45
};

export const OPPORTUNITIES_TYPES = {
  GIFT_CARD: "gift_card",
  EVENT: "event",
  DEAL: "deal",
};

export const getEmailErrorMessage = (email: string) => {

  let message = '';

  if (email && !EMAIL_REGEX.test(email)) {
    if (email.search('@outlook') > 0) {
      message = `Outlook email is not allowed.`;
    }
    if (email.search('@hotmail') > 0) {
      message = `Hotmail email is not allowed.`;
    }
  }
  return message;
};


export const breakTextIntoLines = (inputString: string, maxLength: number) => {
  
// Define a regular expression pattern to match tagged users
let pattern = /@\[([^\]]+)\]\([^)]+\)/g;

// Replace tagged users in the input string
 inputString = inputString.replace(pattern, "@$1");
  const words = inputString.split(/\s+/);
  let result = '';
  for (const word of words) {
    if (word.length > maxLength) {
      const regex = new RegExp(`.{1,${maxLength}}`, 'g');
      const splitWord = word.match(regex).join(' ');
      result += splitWord + ' ';
    } else {
      result += word + ' ';
    }
  }
  return result.trim();
};

export const getDefaultEventTime = (price: string) => {
  return {
        startTime: "09:00",
        endTime: "17:00",
        totalTickets: "10",
        price: price || "0",
        metadata: {},
    };
};

export const getDefaultEventDates= (price: string) => {
  return {
    date: [],
    details: [getDefaultEventTime(price)],
  };
};
