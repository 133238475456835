import {
    STT_DUPLICATED,
    STT_NOT_FOUND,
    STT_UNAUTHORIZED,
} from "src/const/app.const";
import { createApi } from ".";
import toast from 'react-hot-toast';

export const enterWednesdayWins = async () => {
    try {
        const res = await createApi().post<{
            message: string;
            success: boolean;
        }>("/wednesday-wins");

        if (res.status === STT_UNAUTHORIZED) {
            toast.error("Sorry, you are not allowed to enter.");
        }
        if (res.status === STT_DUPLICATED) {
            toast.error("You have already entered the Wellness Giveaway.");
        }
        if (res.status === STT_NOT_FOUND) {
            toast.error("sorry, this game is now over.");
        }
        return res;
    } catch (error) {
        console.log(error);
        throw error;
    }
};


export const hasEnteredWednesdayWins = async () => {
    try {
        const res = await createApi().get<{
            message: string;
            success: boolean;
            date: string
        }>("/wednesday-wins");

        // if (res.status === STT_UNAUTHORIZED) {
        //     toast.error("Unauthorized");
        // }
        if (res.status === STT_NOT_FOUND) {
            toast.error("Sorry, this game is now over.");
        }
        return res;
    } catch (error) {
        console.log(error);
        throw error;
    }
};