import Link from "next/link";
import { Collapse } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import ClearIcon from "@mui/icons-material/Clear";
import { COMMON_X_MARGIN } from "src/const/app.const";

export default function Footer() {
  const [collapse, setCollapse] = useState(true);
  const ref = useRef(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setCollapse(true);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <div className="mt-[130px]" ref={ref}>
      <div
        className={`fixed bottom-0 hidden w-full  bg-gray-50 md:block`}
        style={{
          borderRadius: collapse ? "0px" : "28px 28px 0 0",
          borderWidth: "3.2px",
          zIndex: "2",
          border: !collapse ? "1px solid black" : "1.5px solid #e5e7eb",
        }}
      >
        {collapse ? (
          <div className={`${COMMON_X_MARGIN}  flex justify-between py-5`}>
            <div className="space-x-11">
              <Link href="/" className="text-center text-sm ">
                © 2024 MindKind, Inc.
              </Link>

              <Link href="/support/terms" className="text-center text-sm">
                Terms
              </Link>
              <Link href="/support/faq" className="text-center text-sm ">
                MindKind FAQ
              </Link>
            </div>
            <a style={{ justifyContent: "end" }} className="cursor-pointer">
              <button
                className="cursor-pointer text-center text-sm"
                onClick={() => setCollapse(!collapse)}
              >
                Support
              </button>
            </a>
          </div>
        ) : (
          <Collapse in={!collapse} style={{}}>
            <ClearIcon
              className="mt-[0.8rem] ml-[0.8rem] cursor-pointer"
              onClick={() => {
                setCollapse(!collapse);
              }}
            />
            <div
              className={`mt-[0.6rem] mb-[2rem] ml-[3rem] flex flex-row md:mr-0 md:ml-5 md:justify-evenly lg:mr-[10rem] lg:justify-around`}
              style={{
                fontStyle: "normal",

                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                }}
              >
                <Link href="/support/about" style={{ marginBottom: "20px" }}>
                  About us
                </Link>

                <Link href="/support/faq">MindKind FAQ</Link>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                }}
              >
                <Link href="/support/privacy" style={{ marginBottom: "20px" }}>
                  Privacy Policy
                </Link>
                <Link href="/support/terms">Terms & Conditions</Link>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                }}
              >
                <Link
                  href="/support/businesslaunch"
                  style={{ marginBottom: "20px" }}
                >
                  Business Launch Program
                </Link>
                <div>community@mindkind.life</div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                }}
              >
                <div style={{ margin: "0px 0px 20px 2px" }}>Join us</div>
                <div>
                  <Link
                    target="_blank"
                    rel="nooperner"
                    href="https://www.instagram.com/mindkind.life/"
                  >
                    <InstagramIcon sx={{ fontSize: "2.2rem" }} />
                  </Link>
                  <Link
                    target="_blank"
                    rel="noopener"
                    href="https://www.linkedin.com/company/mindkind-life/"
                  >
                    <LinkedInIcon
                      sx={{ fontSize: "2.2rem", marginLeft: "0.5rem" }}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </Collapse>
        )}
      </div>
    </div>
  );
}
