import { createSlice } from "@reduxjs/toolkit";
import {
  setCurrentStep,
  setDescription,
  setCanUseChatGPT,
  setDiscount,
  setLocation,
  setPrice,
  setType,
  setIsFreeEvent,
  setEventDate,
  setDraftId,
  setEventTitle,
} from "./actionDeals";
import { IEventDates } from "src/components/CreateEventForm/EventDates";
 
const eventDates: IEventDates[] = [];

const initialState = {
  currentStep: {
    step: 1,
    value: "location",
    label: "Location",
  },
  draftId:"",
  location: [],
  type: "",
  list: "",
  description: "",
  canUseChatGPT: true,
  price: 0,
  discount: 10,
  isFreeEvent: false,
  eventDates: eventDates,
  eventTitle: "",
  formData: {
    title: "",
    business_id: "",
    category: "",
    location: "",
    type: "",
    value: "",
    price: "",
    discount: 10,
    quantity: "",
    max_per_person: "",
    expiration_date: "",
    description: "",
    whats_included: [],
    active_from: [],
    media: [],
    active_on: "",
    deal_type: "",
    canUseChatGPT: true,
    deal_sub_type: {},
    start_time: "",
    end_time: "",
    never_expires: false,
    file_upload1: "",
    file_upload2: "",
    image_data1: "",
    image_data2: "",
    image1: "",
    image2: "",
    business_website: "",
    business_name: "",
    business_image: "",
    businessHours: [],
  },
};

const dealSlice = createSlice({
  name: "deal",
  initialState,
  reducers: {
    updateFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    resetDealState: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setCurrentStep.fulfilled, (state, action) => {
        state.currentStep = action.payload;
      })
      .addCase(setDraftId.fulfilled, (state, action) => {
        state.draftId = action.payload;
      })
      .addCase(setLocation.fulfilled, (state, action) => {
        state.location = action.payload;
      })
      .addCase(setType.fulfilled, (state, action) => {
        state.type = action.payload;
      })
      .addCase(setDescription.fulfilled, (state, action) => {
        state.description = action.payload;
      })
      .addCase(setCanUseChatGPT.fulfilled, (state, action) => {
        state.canUseChatGPT = action.payload;
      })
      .addCase(setPrice.fulfilled, (state, action) => {
        state.price = action.payload;
      })
      .addCase(setDiscount.fulfilled, (state, action) => {
        state.discount = action.payload;
      })
      .addCase(setIsFreeEvent.fulfilled, (state, action) => {
        state.isFreeEvent = action.payload;
      })
      .addCase(setEventDate.fulfilled, (state, action) => {
        state.eventDates = action.payload;
      })
      .addCase(setEventTitle.fulfilled, (state, action) => {
        state.eventTitle = action.payload;
      });
  },
});

export const { updateFormData, resetDealState } = dealSlice.actions;
export default dealSlice.reducer;
