import {
  STORAGE_TOKEN,
  STORAGE_EXPIRATION_TIME,
} from "./../components/contexts/ToggleAccountContext";
import { create } from "apisauce";
import * as ReviewService from "./review.service";
import * as AccountService from "./account.service";
import * as BusinessService from "./business.service";
import * as CommunityService from "./community.service";
import * as LocationService from "./location.service";
import * as OpportunitiesService from "./opportunity.service";
import * as WalletService from "./wallet.service";
import * as OrderService from "./orders.service";
import * as PermissionService from "./permission.service";
import * as JackpotService from "./jackpot.service";
import * as WednesdayWinsService from "./wednesday-wins.service";
import * as NewBusinessService from "src/services/Newbusiness.service";
import * as ReactionService from "src/services/reactions.service";
import * as CommentService from "src/services/comment.service";
import * as CouponService from "src/services/coupon.service";
import * as DraftService from "src/services/draft.service"
import * as SocialService from "src/services/social.service";


export interface IApiResponse {
  status: number;
  data: object;
}
const DEFAULT_CONFIG = {
  baseURL: process.env.NEXT_PUBLIC_API_ENDPOINT || "http://localhost:3001/api/",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  timeout: 30000,
};

const createInstance = () => {
  const Api = create(DEFAULT_CONFIG);

  const tokenExpirationDate = localStorage.getItem(STORAGE_EXPIRATION_TIME);
  const isExpired = tokenExpirationDate && +tokenExpirationDate < Date.now();
  const token = localStorage.getItem(STORAGE_TOKEN);
  if (token && isExpired) {
    Api.deleteHeader("authorization");
    localStorage.removeItem(STORAGE_TOKEN); // Avoid double reload
    window.location.reload();
    return Api;
  }

  token && Api.setHeader("authorization", `Bearer ${token}`);

  return Api;
};

export const createApi = () => createInstance();

export {
  AccountService,
  BusinessService,
  CommunityService,
  LocationService,
  ReviewService,
  OpportunitiesService,
  WalletService,
  OrderService,
  PermissionService,
  JackpotService,
  WednesdayWinsService,
  NewBusinessService,
  ReactionService,
  CommentService,
  CouponService,
  DraftService,
  SocialService
};
