// WalletPopup.js

import React, { useContext, useEffect, useState } from "react";
import FilterPopup from "./FilterPopup";
import Image from "next/image";
import useClickOutside from "src/hooks/useClickOutside";
import { AccountService } from "src/services";
import RewardMap from "./rewardMap";
import { AccountContext, AccountContextType } from "src/components/contexts/ToggleAccountContext";

export default function WalletPopup() {
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [welcomeBonus, setWelcomeBonus] = useState(false);
    const [activeInCommunity, setActiveInCommunity] = useState(false);
    const { isLogged, setShowLogin } = useContext<AccountContextType>(AccountContext);
    const handleToggleFilter = () => setIsFilterOpen((prev) => !prev);

    const handleCloseFilter = () => {
        setIsFilterOpen(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!isLogged) {
                    return;
                }

                const response = await AccountService.getInfo();
                const userInfo = response?.data;

                if (!userInfo) {
                    return;
                }

                setWelcomeBonus(userInfo.hasWelcomeBonus);
                setActiveInCommunity(userInfo?.community?.community_is_active ?? false);
            } catch (error) {
                // Handle errors if any
                console.error('Error fetching user info:', error);
            }
        };

        fetchData();
    }, [isLogged]);

    const { ref, exceptionRef } = useClickOutside(handleCloseFilter);

    return (
        <div className="h-11 max-w-[160px] text-sm sm:text-base flex-1 md:relative w-full">
            <div
                className="flex h-full w-full cursor-pointer items-center justify-center ml-0 lg:p-[4px] md:ml-[12px]"
                onClick={handleToggleFilter}
                ref={exceptionRef}
            >
                <Image
                    src={isLogged == true ? '/Wallet_Icon.svg' : '/Wallet_icon_yellow.svg'}
                    alt="filter"
                    width={32}
                    height={32}
                />
            </div>
            {isFilterOpen && (
                <div ref={ref}>
                    <FilterPopup
                        align="right"
                        width="w-[calc(100vw-50px)] ml-6 md:ml-0 md:w-96"
                    >
                        <RewardMap isLogged={isLogged} welcomeBonus={welcomeBonus} activeInCommunity={activeInCommunity} setShowLogin={setShowLogin} />
                    </FilterPopup>
                </div>
            )}
        </div>
    );
}
