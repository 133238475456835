import Image from "next/image";
import Link from "next/link";
import React, { useEffect, useRef } from "react";
import { twMerge } from "tailwind-merge";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import useMobile from "src/hooks/useMobile";

interface ModalProps {
  open: boolean;
  title: string;
  children?: React.ReactNode;
  className?: string;
  onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({
  open,
  onClose,
  title,
  children,
  className,
}) => {
  const wrapperRef = useRef(null);
  const isMobile = useMobile();
  useEffect(() => {
    const body = document.body;
    if (open) {
      body.style.overflow = "hidden"; // disable scrolling on the body element
    } else {
      body.style.overflow = "auto"; // enable scrolling on the body element
    }
  }, [open]);
  function closedModal() {
    const body = document.body;
    body.style.overflow = "auto";
    return
  }

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
       closedModal()
      onClose(event);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  if (!open) {
    return null;
  }
  return (
    <>
      {Boolean(open) && (
        <>
          <div
            ref={wrapperRef}
            className={twMerge(
              `fixed inset-0 z-50 m-auto flex flex-col items-center md:rounded-[10px] md:border-[1px] border-[#9B9A9A] bg-[#FBFBFB] md:h-fit md:w-[65%]  lg:w-[40%] ${className}`
            )}
            style={{
              left: `${onClose.pageX - 200}px`,
              top: `${onClose.pageY}px`,
            }}
          >
            <div className="relative flex w-full justify-center border-b-[1px] border-b-[#9B9A9A] py-5">
              {isMobile ? (
                <div
                  onClick={(e) => {
                    closedModal()
                    onClose(e)}}
                  className="absolute left-[6%] top-[50%] z-10 flex flex-shrink-0 -translate-y-[25px] items-center"
                >
                  <ArrowBackIosNewIcon className="absolute top-3 " />
                </div>
              ) : (
                <svg
                  onClick={(e) => {
                    closedModal()
                    onClose(e)}}
                  width="26"
                  height="26"
                  viewBox="0 0 28 28"
                  fill="none"
                  className="absolute left-[6%] top-[50%] z-10 flex flex-shrink-0 -translate-y-[13px] cursor-pointer items-center"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M26.6004 1.42043C25.8204 0.64043 24.5604 0.64043 23.7804 1.42043L14.0004 11.1804L4.22043 1.40043C3.44043 0.62043 2.18043 0.62043 1.40043 1.40043C0.62043 2.18043 0.62043 3.44043 1.40043 4.22043L11.1804 14.0004L1.40043 23.7804C0.62043 24.5604 0.62043 25.8204 1.40043 26.6004C2.18043 27.3804 3.44043 27.3804 4.22043 26.6004L14.0004 16.8204L23.7804 26.6004C24.5604 27.3804 25.8204 27.3804 26.6004 26.6004C27.3804 25.8204 27.3804 24.5604 26.6004 23.7804L16.8204 14.0004L26.6004 4.22043C27.3604 3.46043 27.3604 2.18043 26.6004 1.42043Z"
                    fill="black"
                  />
                </svg>
              )}
              <p className="text-2xl font-normal">{title}</p>
            </div>
            {children}
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
        </>
      )}
    </>
  );
};

export default Modal;
