import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Disclosure } from "@headlessui/react";
import { NextRouter, useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";

export type BreadCrumbNavProps = {
  label: string;
  noBack?: boolean;
  onBack?: (router: NextRouter) => void;
};

export default function BreadCrumbNav({
  label,
  onBack,
  noBack,
}: BreadCrumbNavProps) {
  const router = useRouter();
  const [hasHistory, setHasHistory] = useState(false);

  useEffect(() => {
    setHasHistory(window.history.length > 1);
  }, []);

  const isOpportunityPage =
    router.pathname.includes("/opportunity") ||
    router.pathname.includes("/detail") ||
    router.pathname.includes("/preview");
   

    const isPostPage =
    router.pathname.includes("/community/post");

  const goBack = useCallback(() => {
    // Override default logic
    if (onBack) {
      return onBack(router);
    }

    if (hasHistory) {
      router.back();
    } else {
      router.push("https://mindkind.life");
    }
  }, [onBack, router, hasHistory]);

  return (
    <Disclosure
      as="nav"
      className="navbar flex min-h-[73px] w-full items-center border border-b bg-gray-50"
    >
      <div
        className={`${
          isOpportunityPage || isPostPage ? "gap-2" : "mx-auto"
        } flex w-full items-center px-2 sm:px-6 lg:px-8`}
      >
        {!noBack && (
          <div
            className={`${
              isOpportunityPage || isPostPage ? "" : "absolute top-3 left-2"
            } cursor-pointer rounded-full py-2 pl-1`}
            onClick={goBack}
          >
            <ArrowBackIosNewIcon />
          </div>
        )}
        <div
          className={`flex h-16 ${
            isOpportunityPage ? "w-[calc(100%-180px)]" : "w-full"
          } items-center justify-center`}
        >
          <span
            className={`${
              isOpportunityPage || isPostPage ? "text-left text-lg" : "text-center text-xl"
            } w-full font-medium`}
          >
            {label}
          </span>
        </div>
      </div>
    </Disclosure>
  );
}